<template>
  <div class="section">
    <div class="container">
      <div class="is-size-4" v-if="buttonText == 'Update'">Update Account</div>
      <div class="is-size-4" v-else>Create Account</div>

      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <transition name="fade" mode="out-in" appear>
          <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
            <fieldset>
              <div class="column is-12" :style="styles.formRow">
                <span class="column is-3" style="white-space: nowrap">
                  <b-field label="Account Number">
                    <b-numberinput
                      name="AccountNumber"
                      vid="accountNumber"
                      v-model="formData.accountNumber"
                      required
                    />
                  </b-field>
                </span>
              </div>
              <div class="column is-12" :style="styles.formRow">
                <span class="column is-3" style="white-space: nowrap">
                  <b-field label="Sub Account Number">
                    <b-numberinput
                      name="SubAccountNumber"
                      vid="subAccountNumber"
                      v-model="formData.subAccountNumber"
                      required
                    />
                  </b-field>
                </span>
              </div>
              <div class="column is-12">
                <valid-input
                  style="width:50px !important;"
                  name="AccountName"
                  label="Account Name"
                  maxlength="100"
                  type="text"
                  vid="accountName"
                  placeholder="Account Name"
                  spellcheck="true"
                  aria-label="AccountName"
                  class="is-small"
                  rules="max:100|required"
                  v-model="formData.AccountName"
                />
              </div>
              <div class="column is-8" v-if="accountTypeList">
                <valid-select
                  label="Account Type"
                  placeholder="Account Type"
                  vid="accountType"
                  v-model="formData.AccountType"
                  aria-required="The account type is required"
                  rules="required"
                >
                  <option
                    v-for="option in accountTypeList"
                    :value="option.value"
                    :key="option.value"
                    >{{ option.label }}</option
                  >
                </valid-select>
              </div>
              <div class="column is-8" v-if="fundTypeList">
                <valid-select
                  label="Fund Type"
                  placeholder="Fund Type"
                  vid="fundType"
                  v-model="formData.FundType"
                  aria-required="The account type is required"
                  rules="required"
                >
                  <option
                    v-for="option in fundTypeList"
                    :value="option.value"
                    :key="option.value"
                    >{{ option.label }}</option
                  >
                </valid-select>
              </div>
              <div class="column is-8" v-if="glExpenseCategoryList">
                <valid-select
                  label="Expense Category"
                  placeholder="Expense Category"
                  vid="expenseCategoryId"
                  v-model="formData.ExpenseCategoryId"
                >
                  <option
                    :value="null"
                    disabled
                    :style="{ color: '#888' }"
                    v-if="!loading && glExpenseCategoryList && glExpenseCategoryList.length === 0"
                  >
                    An expense category has not yet been added.
                  </option>
                  <option
                    v-for="option in glExpenseCategoryList"
                    :value="option.value"
                    :key="option.value"
                    >{{ option.label }}</option
                  >
                </valid-select>
              </div>
              <div class="column is-12 pl-5">
                <b-checkbox
                  class="has-left-text"
                  v-model="formData.DefaultReceivingAccount"
                  @click.native="hasDefaultReceivingAccount = !hasDefaultReceivingAccount"
                  >Default Receiving Account</b-checkbox
                >
              </div>
              <div class="pb-5">
                <button type="submit" :disabled="loading" class="button is-primary is-rounded">
                  {{ buttonText }}
                </button>
              </div>
            </fieldset>
            <fieldset>
              <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                <div class="field">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </fieldset>
          </form>
        </transition>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
/*
    Locations:
        /management/managechartofaccounts
*/

// Components
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ValidationError } from '@/services/errors'
import { ToastProgrammatic as Toast } from 'buefy'
import { mapState, mapGetters } from 'vuex'
import $ from 'jquery'

// components
import ValidInput from '@/components/inputs/ValidInput'
import ValidSelect from '@/components/inputs/ValidSelect'

function notifyError(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-danger'
  })
}

let DEFAULT_FORM_VALUES = {
  accountNumber: 0,
  subAccountNumber: 0,
  accountName: '',
  accountType: '',
  fundType: '',
  expenseCategoryId: 0,
  defaultReceivingAccount: false
}

export default {
  name: 'InvoiceAccountModal',

  props: {
    accountID: Number,
    account: Object
  },

  components: {
    ValidInput,
    ValidSelect,
    ValidationObserver,
    ValidationProvider
  },

  data: function() {
    return {
      formComplete: false,
      formData: {
        ...DEFAULT_FORM_VALUES
      },
      loading: false,
      isDebug: true,
      selectedRow: null,
      buttonText: 'Add',
      hasDefaultReceivingAccount: false,
      styles: {
        formRow: {
          padding: '10px',
          whiteSpace: 'nowrap'
        }
      }
    }
  },

  created() {},

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    }),
    ...mapGetters('accounting', ['accountTypeList']),
    ...mapGetters('accounting', ['fundTypeList']),
    ...mapGetters('accounting', ['glExpenseCategoryList'])
  },

  mounted() {
    this.loading = true
    this.$store.dispatch('accounting/getAccountTypeList')
    this.$store.dispatch('accounting/getFundTypeList')
    this.$store.dispatch('accounting/getExpenseCategoriesList')

    if (this.isDebug == true) console.debug('mounted account =' + JSON.stringify(this.account))

    if (
      this.account &&
      this.account.accountId != undefined &&
      this.account.accountId &&
      this.account.accountId > 0
    ) {
      if (this.isDebug == true) console.debug('this.account=' + JSON.stringify(this.account))
      this.formData.accountNumber = this.account.accountNumber
      this.formData.subAccountNumber = this.account.subAccountNumber
      this.formData.AccountName = this.account.description
      this.formData.AccountType = this.account.accountType
      this.formData.FundType = this.account.fundType
      this.formData.ExpenseCategoryId =
        this.account.expenseCategoryId && this.account.expenseCategoryId != undefined
          ? this.account.expenseCategoryId
          : 0
      this.formData.Description = this.account.description
      this.formData.DefaultReceivingAccount = this.account.isDefaultReceivingAccount
      this.buttonText = 'Update'
    } else {
      this.formData = { ...DEFAULT_FORM_VALUES }
      this.$refs.form.reset()
      this.buttonText = 'Add'
    }

    this.loading = false
  },

  methods: {
    getPayload() {
      if (this.isDebug == true) console.debug('this.formData=' + JSON.stringify(this.formData))

      const payload = {
        accountId:
          this.account && this.account.accountId && this.account.accountId != undefined
            ? this.account.accountId
            : 0,
        accountNumber:
          this.formData.accountNumber && this.formData.accountNumber != undefined
            ? this.formData.accountNumber
            : 0,
        subAccountNumber:
          this.formData.subAccountNumber && this.formData.subAccountNumber != undefined
            ? this.formData.subAccountNumber
            : 0,
        hoaId: this.hoaId,
        accountType:
          this.formData.AccountType && this.formData.AccountType != undefined
            ? this.formData.AccountType
            : '',
        fundType:
          this.formData.FundType && this.formData.FundType != undefined
            ? this.formData.FundType
            : '',
        description:
          this.formData.AccountName && this.formData.AccountName != undefined
            ? this.formData.AccountName
            : '',
        isDefaultReceivingAccount:
          this.formData.DefaultReceivingAccount &&
          this.formData.DefaultReceivingAccount != undefined
            ? this.formData.DefaultReceivingAccount
            : false,
        expenseCategoryId:
          this.formData.ExpenseCategoryId && this.formData.ExpenseCategoryId != undefined
            ? this.formData.ExpenseCategoryId
            : 0,
        exceedsBudgetByPercent: 0,
        exceedsBudgetByAmount: 0,
        exceedsBudgetByAnnualPercent: 0,
        exceedsBudgetByAnnualAmount: 0
      }

      return payload
    },
    async onFormSubmit() {
      if (!this.formData || (this.formData == undefined && this.accountID && this.accountID > 0)) {
        notifyError('There was a problem submitting your new G/L Account.')
        return
      }

      try {
        if (this.buttonText == 'Update') {
          const updatePayload = this.getPayload()
          this.$store.dispatch('accounting/updateAccount', {
            payload: updatePayload,
            done: () => {
              // auto-close modal
              this.$emit('update:toggle', (this.toggle = false))
              $('#clickclose').click()
              this.loading = false
            }
          })
        } else {
          //Add
          const payload = this.getPayload()

          if (this.isDebug == true) console.debug('payload=' + JSON.stringify(payload))

          let validationMessages = ''

          if (payload.accountNumber < 0) {
            validationMessages = '<div>Please enter an account number.</div>'
          }

          if (payload.subAccountNumber < 0) {
            validationMessages += '<div>Please enter a sub account number.</div>'
          }

          if (payload.accountType == '') {
            validationMessages += '<div>Please enter an account type.</div>'
          }

          if (payload.fundType == '') {
            validationMessages += '<div>Please enter a fund type.</div>'
          }

          if (payload.description == '') {
            validationMessages += '<div>Please enter a description.</div>'
          }

          if (validationMessages != '') {
            notifyError(validationMessages)
            return
          }

          this.loading = true
          this.$store.dispatch('accounting/addAccount', {
            payload: payload,
            done: () => {
              // auto-close modal
              this.$emit('update:toggle', (this.toggle = false))
              $('#clickclose').click()
              this.loading = false
            }
          })
        }
      } catch (e) {
        // If this is a validation error, get the details for each field
        if (e instanceof ValidationError) {
          this.$refs.form.setErrors(e.fields)
        }

        notifyError('There was a problem saving the G/L Account.' + e)
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
.step-details {
  background-color: #fff !important;
  padding-left: 0.25rem !important;
  padding-right: 0.5rem !important;
}

.b-steps.is-small .steps {
  margin-top: 1rem;
}

.b-steps .steps + .step-content {
  padding-top: 0 !important;
}

.step-navigation {
  display: flex;
  justify-content: flex-end;
}

.my-grid-class {
  height: 400px;
}

tbody {
  min-height: 100px !important;
  overflow: auto !important;
}
</style>
