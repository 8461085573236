<template>
  <div>
    <h5>Account Details</h5>
    <json-box :value="account"></json-box>
  </div>
</template>

<script>
import JsonBox from '@/components/JsonBox'
import { mapActions, mapState } from 'vuex'

export default {
  data: () => ({
    loading: false
  }),
  components: { JsonBox },
  computed: {
    ...mapState({
      account: state => state.hoa.glAccount
    })
  },
  methods: {
    ...mapActions('hoa', ['loadGLAccount']),

    async reload() {
      console.log('Reloading')
      this.loading = true
      await this.loadGLAccount(this.$route.params.id)
      this.loading = false
    }
  }
}
</script>
