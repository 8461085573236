<template>
  <PageContent :title="$t('generalLedger.listTitle')">
    <div class="list-content">
      <div class="box">
        <div class="columns">
          <div class="column is-4">
            <b-field label="Account">
              <b-autocomplete
                :keep-first="keepFirst"
                size="is-small"
                field="label"
                icon="magnify"
                :loading="isFetching"
                placeholder="e.g. 1100 or Operating"
                :data="accountFilterSuggestions"
                v-model="accountFilterQuery"
                required="true"
                @typing="getAsyncData"
                @select="option => (selectedAccount = option)"
                :open-on-focus="openOnFocus"
                clearable
              >
              </b-autocomplete>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field label="Start Date">
              <b-datepicker
                v-model="accountFilterStartDate"
                placeholder="Click to select..."
                icon="calendar-today"
                :max-date="maxStartDate"
                :min-date="minStartDate"
                trap-focus
                editable
              >
              </b-datepicker>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field label="End Date">
              <b-datepicker
                placeholder="Click to select..."
                icon="calendar-today"
                v-model="accountFilterEndDate"
                :max-date="maxEndDate"
                :min-date="minEndDate"
                trap-focus
                editable
              >
              </b-datepicker>
            </b-field>
          </div>
        </div>

        <b-table
          class="pb-5 has-filters"
          :data="transactions || []"
          :loading="loading"
          :selected.sync="selectedRow"
          striped
          sticky-header
          narrowed
        >
          <template slot="empty">
            <section class="section" v-if="!loading">
              <div class="content has-text-grey has-text-centered">
                <p>Select an account to view transactions.</p>
              </div>
            </section>
          </template>

          <b-table-column label="Date" field="transactionDate" sortable v-slot="props">
            {{ props.row.transactionDate | shortDate }}
          </b-table-column>

          <b-table-column field="debitAmount" label="Debit Amount" sortable numeric v-slot="props">
            <span
              class="has-text-danger"
              v-if="
                props.row &&
                  props.row != undefined &&
                  props.row.debitAmount &&
                  props.row.debitAmount != undefined
              "
            >
              {{ props.row.debitAmount | accounting }}
            </span>
          </b-table-column>

          <b-table-column
            field="creditAmount"
            label="Credit Amount"
            sortable
            numeric
            v-slot="props"
          >
            <span
              class="has-text-success"
              v-if="
                props.row &&
                  props.row != undefined &&
                  props.row.creditAmount &&
                  props.row.creditAmount != undefined
              "
            >
              {{ (0 - props.row.creditAmount) | accounting }}
            </span>
          </b-table-column>

          <b-table-column field="description" label="Description" sortable v-slot="props">
            {{ props.row.description }}
          </b-table-column>
        </b-table>

        <div
          class="pt-5 box columns"
          :style="{ backgroundColor: '#f8f9fa', marginLeft: 0, marginRight: 0 }"
          v-if="transactions && transactions != undefined"
        >
          <div class="column is-fifth">
            <span class="font-bold">Beginning Balance:</span>
            {{ beginningBalance | currency }}
          </div>
          <div class="column is-fifth">
            <span class="font-bold">Total Debits:</span> {{ summedDebits | currency }}
          </div>
          <div class="column is-fifth">
            <span class="font-bold">Total Credits:</span> {{ summedCredits | currency }}
          </div>
          <div class="column is-fifth">
            <span class="font-bold">Net Change:</span> {{ netChange | currency }}
          </div>
          <div class="column is-fifth">
            <span class="font-bold">Ending Balance:</span> {{ endingBalance | currency }}
          </div>
        </div>
      </div>
    </div>
  </PageContent>
</template>

<script>
import PageContent from '@/containers/PageContent'

import { watch } from './parts/watch'
import { methods } from './parts/methods'
import { data } from './parts/data'
import { computed } from './parts/computed'

export default {
  components: {
    PageContent
  },

  data,
  computed,
  methods,
  watch,

  mounted() {
    this.reload()
  },

  i18n: {
    messages: {
      en: { generalLedger: { listTitle: 'General Ledger' } }
    }
  }
}
</script>
