<template>
  <PageContentLayoutOnly>
    <ModernTable
      :filters="filters"
      :rows="chartOfAccounts"
      :columns="columns"
      :pagination="pagination"
    >
      <template v-slot:header>
        <h3 style="display: inline-block">{{ $t('chartOfAccounts.title') }}</h3>

        <div :style="{ display: 'flex', justifyContent: 'space-between' }">
          <nav class="level">
            <div class="level-left">
              <b-button @click="createAccountModal()" size="is-small" type="is-primary" rounded>
                Create Account
              </b-button>
            </div>
          </nav>

          <b-button
            type="is-ghost"
            @click="toggleFilters"
            :style="styles.filterButton"
            :class="'filter-button ' + (filters.show ? 'active' : '')"
          >
            <b-icon pack="mdi" icon="filter-outline" size="is-small" style="display: inline-block">
            </b-icon>
            <div style="display: inline-block">Filter</div>
          </b-button>
        </div>
      </template>
    </ModernTable>

    <Modal :toggle.sync="toggle">
      <AccountModal :toggle="toggle" :account="selectedAccount"> </AccountModal>
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ModernTable from '@/components/tables/Modern/Table'
import Modal from '@/components/Modal'
import AccountModal from '@/components/Modals/AccountModal'

import { methods } from './keys/methods'
import { data } from './keys/data'

export default {
  name: 'ChartOfAccounts',
  components: {
    PageContentLayoutOnly,
    ModernTable,
    Modal,
    AccountModal
  },

  data,

  computed: {
    ...mapGetters('accounting', ['glChartOfAccountsList']),
    ...mapGetters('accounting', ['glTransactions']),
    ...mapGetters('accounting', ['accountTypeList']),
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  watch: {
    hoaId() {
      this.reload()
    },

    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  mounted() {
    this.reload()
  },

  methods,

  i18n: {
    messages: {
      en: { chartOfAccounts: { title: 'Chart of Accounts' } }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_variables';

@media screen and (max-width: $tablet) {
  .filter-button {
    display: none;
  }
}

div.amountFrom {
  width: 60px !important;
}

.filter-button {
  color: #838383 !important;

  &.active {
    color: $link !important;
  }
}

.button.is-ghost {
  background: none;
  border-color: transparent;
  color: #7957d5;
  text-decoration: none;
}
</style>
