import { mapActions } from 'vuex'
import debounce from 'lodash/debounce'

export const methods = {
  ...mapActions('hoa', ['loadGLAccounts', 'loadGLAccountTransactions']),

  async reload() {
    this.loading = true
    await this.loadGLAccounts()
    await this.loadGLAccountTransactions()
    this.loading = false
  },

  async reloadTransactions() {
    this.loading = true
    const { selectedAccount, accountFilterStartDate, accountFilterEndDate } = this
    await this.loadGLAccountTransactions({
      accountID: selectedAccount && selectedAccount.accountId,
      filterStartDate: accountFilterStartDate,
      filterEndDate: accountFilterEndDate
    })

    if (
      this.transactions &&
      this.transactions != undefined &&
      this.transactions[0] &&
      this.transactions[0] != undefined &&
      this.transactions[0].beginningBalance != undefined
    ) {
      this.beginningBalance = this.transactions[0].beginningBalance
      this.endingBalance = this.transactions[0].endingBalance
      this.summedDebits = this.transactions[0].totalDebits
      this.summedCredits = this.transactions[0].totalCredits
      this.netChange = this.transactions[0].netChange
    } else {
      this.beginningBalance = 0
      this.endingBalance = 0
      this.summedDebits = 0
      this.summedCredits = 0
      this.netChange = 0
    }

    this.loading = false
  },
  getAsyncData: debounce(function(name) {
    // String update
    if (this.name !== name) {
      this.name = name
      this.accountFilterSuggestions = []
    }

    console.log('accounts=' + this.name)

    // String cleared
    if (!name.length) {
      this.accountFilterSuggestions = []
      return
    }

    this.isFetching = true
    if (this.isDebug == true) console.debug('this.name=' + this.name)

    this.accountFilterSuggestions = this.accounts.filter(i =>
      i.label.toLowerCase().includes(this.name.toLowerCase())
    )

    console.log('filter=' + JSON.stringify(this.accountFilterSuggestions))

    this.isFetching = false
  }, 500),
  getMoreAsyncData: debounce(function() {
    this.getAsyncData(this.name)
  }, 250)
}
