export const watch = {
  hoaId() {
    this.reload()
  },

  // go to details page when selected
  selectedRow(row) {
    console.log('selected', { row })
    // this.$router.push({ name: 'account', params: { id: row.accountID } })
  },

  selectedAccount: 'reloadTransactions',
  accountFilterStartDate: 'reloadTransactions',
  accountFilterEndDate: 'reloadTransactions'

  // async selectedAccount() {
  //   this.reloadTransactions()
  // }
}
