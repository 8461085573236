export function data() {
  return {
    accountFilterStartDate: null,
    accountFilterEndDate: null,

    accountFilterQuery: null,
    selectedAccount: null,
    loading: false,
    selectedRow: null,
    isFetching: false,
    openOnFocus: true,
    keepFirst: true,
    beginningBalance: 0,
    endingBalance: 0,
    summedDebits: 0,
    summedCredits: 0,
    netChange: 0
  }
}
