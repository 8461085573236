import { mapState } from 'vuex'
// import moment from 'moment'

export const computed = {
  ...mapState({
    transactions: state => state.hoa.glAccountTransactions,
    accounts: state => state.hoa.glAccounts,
    hoaId: state => state.user.selectedHoaId
  }),

  accountFilterSuggestions() {
    if (this.accountFilterQuery) {
      return this.accounts.filter(
        a => a._search.indexOf(this.accountFilterQuery.toLowerCase()) !== -1
      )
    } else {
      return this.accounts
    }
  },

  minStartDate() {
    return null
    // return moment('01-01-1980').toDate()
  },
  maxStartDate() {
    return null
    /*
    return moment(this.accountFilterEndDate)
      .subtract(1, 'day')
      .toDate()
    */
  },

  minEndDate() {
    return null

    /*
      return moment(this.accountFilterStartDate)
        .add(1, 'day')
        .toDate()
      */
  },
  maxEndDate() {
    return null
    //return moment().toDate()
  }
}
