import Button from '@/components/buttons/Button'
import _get from 'lodash/get'
import { ToastProgrammatic as Toast } from 'buefy'

function notifyError(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-danger'
  })
}

export const methods = {
  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  confirmDeleteAccount(id, accountName) {
    this.$buefy.dialog.confirm({
      title: 'Deleting G/L Account',
      message: `Are you sure you want to <b>delete</b> the G/L Account for ${accountName}?`,
      confirmText: 'Delete G/L Account',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteAccount(id)
    })
  },

  determineAccountRows() {
    if (this.isDebug == true) console.debug('determineAccountRows()...')

    if (this.glChartOfAccountsList) {
      this.chartOfAccounts = this.glChartOfAccountsList.map(i => {
        var tempAccount = _get(i, 'accountNumber', '')
        var tempSubAccount = _get(i, 'subAccountNumber', '')
        var accountId = _get(i, 'accountId', 0)
        var accountName = _get(i, 'description', '')
        const account = `${tempAccount}-${tempSubAccount.toString().padStart(3, '0')}`
        const expenseCategory =
          i.glExpenseCategory !== undefined &&
          i.glExpenseCategory &&
          i.glExpenseCategory.name !== undefined &&
          i.glExpenseCategory.name
            ? i.glExpenseCategory.name
            : ''

        return {
          account: account,
          accountName: accountName,
          accountType: _get(i, 'accountType', ''),
          fundType: _get(i, 'fundType', ''),
          expenseCategory: expenseCategory,
          edit: {
            component: Button,
            props: {
              onClick: () => {
                this.processAccountUpdate(i)
              },
              text: 'Edit'
            }
          },
          erase: {
            component: Button,
            props: {
              onClick: () => {
                this.confirmDeleteAccount(accountId, accountName)
              },
              text: 'Delete',
              visible: !this.isPostedAlready
            }
          }
        }
      })
    } else {
      console.log('else determineAccountRows...')
    }
  },

  async reload() {
    this.loading = true

    try {
      this.$store.dispatch('accounting/getGlChartOfAccountsList').then(() => {
        this.determineAccountRows()
      })
    } catch (e) {
      console.debug('error in method for retrieving chart of accounts:' + e)
    }

    this.loading = false
  },

  processAccountUpdate(account) {
    this.toggle = true
    this.selectedAccount = account
  },

  createAccountModal() {
    if (this.isDebug == true) console.debug('in createAccountModal...')
    this.selectedAccount = null
    this.toggle = true
  },

  async deleteAccount(id) {
    if (this.isDebug == true) console.debug('...in deleteAccount id=' + id)

    if (id <= 0) {
      notifyError('There was a problem deleting your G/L account.')
      return
    }

    this.glTransactions = []

    //Check for G/L transactions. This cannot be deleted if there are transactions
    this.$store
      .dispatch('accounting/getGlTransactionList', {
        accountID: id
      })
      .then(() => {
        if (this.glTransactions && this.glTransactions.length > 0) {
          if (this.isDebug == true)
            console.debug('glTransactions=' + JSON.stringify(this.glTransactions))

          notifyError(
            'This G/L account cannot be deleted because it currently has associated transactions.'
          )
          this.loading = false
          return
        }

        this.$store.dispatch('accounting/deleteGlAccount', {
          accountID: id,
          done: () => {
            this.loading = false
            this.reload()
          }
        })
      })

    this.loading = false
  }
}
