var field = {
  width: '100px',
  display: 'inline-block',
  paddingRight: '6px',
  textAlign: 'left'
}

export const data = () => ({
  isDebug: true,
  toggle: false,
  isQuery: false,
  loading: false,
  selectedAccount: null,
  isFetching: false,
  data: [],
  chartOfAccounts: [],

  filters: {
    show: true
  },

  pagination: {
    perPage: 10
  },

  columns: [
    {
      field: 'account',
      label: 'Account',
      aria: 'Account',
      width: '10%',
      numeric: true,
      sortable: true,
      searchable: true
    },
    {
      field: 'accountName',
      label: 'Account Name',
      width: '30%',
      sortable: true,
      searchable: true
    },
    {
      field: 'accountType',
      label: 'Account Type',
      width: '15%',
      sortable: true,
      searchable: true
    },
    {
      field: 'fundType',
      label: 'Fund Type',
      width: '15%',
      sortable: true,
      searchable: true
    },
    {
      field: 'expenseCategory',
      label: 'Expense Category',
      width: '15%',
      sortable: true,
      searchable: true
    }
  ],

  styles: {
    formRow: {
      padding: '10px',
      whiteSpace: 'nowrap'
    },
    filterButton: {
      float: 'right',
      marginTop: '4px'
    },
    amountField: Object.assign({}, field, {
      width: '125px'
    })
  }
})
